import axios from 'axios'
import { log4XXApiErrors, mapError, mapUserApiError } from '@netpurpose/api'
import { httpStatusCode } from '@netpurpose/utils'
import { attachAuthToken } from '#services/api/helpers/requestInterceptors'
import { getToken, logout } from '#services/auth'
import { config } from '#services/config'

// TODO: this createApi function should and will be centralised in the api package
export const createApi = (baseUrl: string) => {
  const api = axios.create({
    baseURL: baseUrl,
  })

  api.interceptors.request.use(attachAuthToken)

  api.interceptors.response.use(
    (response) => response,
    async (error) => {
      log4XXApiErrors(error)
      if (error?.response?.status === httpStatusCode.unauthorized) {
        const token = await getToken(true)
        if (!token) {
          logout()
        }
      }

      if (baseUrl === config.userServiceBaseUrl) {
        return Promise.reject(mapUserApiError(error.response))
      }

      return Promise.reject(mapError(error.response))
    },
  )

  return api
}
