import { Actions, State } from '#context/tasks'

export const reducer = (state: State, action: Actions): State => {
  switch (action.actionType) {
    case 'new': {
      return {
        ...state,
        [action.taskType]: {
          ...state[action.taskType],
          [action.token]: {
            status: 'pending',
            initial: action.initial,
            portfolioName: action.portfolioName,
            metricConfigId: action.metricConfigId,
            entityName: action.entityName,
          },
        },
      }
    }
    case 'statusUpdate': {
      switch (action.newStatus) {
        case 'pending': {
          return state
        }
        case 'complete': {
          return {
            ...state,
            [action.taskType]: {
              ...state[action.taskType],
              [action.token]: {
                ...state[action.taskType][action.token],
                status: 'complete',
                result: action.result,
              },
            },
          }
        }
        case 'failed': {
          return {
            ...state,
            [action.taskType]: {
              ...state[action.taskType],
              [action.token]: {
                ...state[action.taskType][action.token],
                status: 'failed',
              },
            },
          }
        }
        default: {
          return state
        }
      }
    }
    case 'remove': {
      if (!action.result) {
        return state
      }
      const index = Object.values(state[action.taskType]).findIndex(
        (task) => task.result === action.result,
      )
      const stateCopy = structuredClone(state)
      const key = Object.keys(state[action.taskType])[index]
      if (!!key) {
        delete stateCopy[action.taskType][key]
      }

      return stateCopy
    }
    default: {
      throw new Error(`Unhandled action type: ${action}`)
    }
  }
}
