import 'firebase/compat/auth'
import dynamic from 'next/dynamic'
import { FC } from 'react'
import { config } from '#services/config'

const FlagProvider = dynamic(() => import('@unleash/proxy-client-react'), { ssr: false })

export const UnleashProvider: FC<{ children: React.ReactNode }> = ({ children }) => (
  <FlagProvider config={config.unleash}>{children}</FlagProvider>
)
