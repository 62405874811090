import { useRouter } from 'next/router'
import { config } from '#services/config'

export const useRedirectWithOriginalUrl = () => {
  const router = useRouter()

  return {
    handleRedirect: () => {
      const hasOriginalRequestUrlForApp =
        window.history.length > 1 && document.referrer.indexOf(window.location.host) !== -1

      if (hasOriginalRequestUrlForApp) {
        router.push(document.referrer)
      } else {
        router.push(config.routes.landingPage)
      }
    },
  }
}
