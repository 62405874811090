import { QueryClient } from '@tanstack/react-query'
import { Dispatch } from 'react'
import { ResultApi } from '@netpurpose/api'
import { AlertBannerType } from '#components/common/AlertBanner'
import {
  Action,
  Actions,
  DataDictionaryExport,
  EntityExport,
  PortfolioCreation,
  PortfolioExport,
  State,
  Task,
  TaskType,
} from '#context/tasks'
import { assertNever, entries } from '#services/typeHelpers'
import { PortfolioPAIsExport, PortfolioSDGRevenueExport, PortfolioSDGsExport } from './types'

const getTaskResultAndDispatchStatusUpdate = async <T extends Task>(
  resultApi: ResultApi,
  token: string,
  taskType: T['type'],
  dispatch: Dispatch<Action<T>>,
  showAlertBanner: (type: unknown, text?: string | undefined) => void,
  queryClient: QueryClient,
) => {
  const result = await resultApi.getResult<T['result']>(token)
  // @ts-expect-error
  dispatch({
    actionType: 'statusUpdate',
    taskType,
    token,
    newStatus: result.status,
    result: result.data,
  })

  queryClient.invalidateQueries({ queryKey: ['listExports'] })

  if (result.status === 'failed') {
    showAlertBanner(AlertBannerType.GeneralError)
  }
}

export const checkPendingTaskResults = (
  resultApi: ResultApi,
  state: State,
  dispatch: Dispatch<Actions>,
  queryClient: QueryClient,
  showAlertBanner: (type: unknown, text?: string | undefined) => void,
) => {
  entries(state).forEach(([taskType, taskStateById]) => {
    entries(taskStateById)
      .filter(([_, taskState]) => taskState.status === 'pending')
      .forEach(async ([token]) => {
        switch (taskType) {
          case TaskType.PortfolioCreation: {
            const result = await resultApi.getResult<PortfolioCreation['result']>(token)
            if (result.status === 'unknown') {
              return null
            }
            if (result.status !== 'pending') {
              queryClient.invalidateQueries({ queryKey: ['portfolios'] })
              queryClient.invalidateQueries({ queryKey: ['listExports'] })
            }
            if (result.status === 'failed') {
              showAlertBanner(AlertBannerType.GeneralError)
            }
            // @ts-expect-error
            return dispatch({
              actionType: 'statusUpdate',
              taskType,
              token,
              newStatus: result.status,
              result: result.data,
            })
          }
          case TaskType.PortfolioExport: {
            return getTaskResultAndDispatchStatusUpdate<PortfolioExport>(
              resultApi,
              token,
              taskType,
              dispatch,
              showAlertBanner,
              queryClient,
            )
          }
          case TaskType.PortfolioPAIsExport: {
            return getTaskResultAndDispatchStatusUpdate<PortfolioPAIsExport>(
              resultApi,
              token,
              taskType,
              dispatch,
              showAlertBanner,
              queryClient,
            )
          }
          case TaskType.PortfolioSDGsExport: {
            return getTaskResultAndDispatchStatusUpdate<PortfolioSDGsExport>(
              resultApi,
              token,
              taskType,
              dispatch,
              showAlertBanner,
              queryClient,
            )
          }
          case TaskType.PortfolioSDGRevenueExport: {
            return getTaskResultAndDispatchStatusUpdate<PortfolioSDGRevenueExport>(
              resultApi,
              token,
              taskType,
              dispatch,
              showAlertBanner,
              queryClient,
            )
          }
          case TaskType.EntityExport: {
            return getTaskResultAndDispatchStatusUpdate<EntityExport>(
              resultApi,
              token,
              taskType,
              dispatch,
              showAlertBanner,
              queryClient,
            )
          }
          case TaskType.DataDictionaryExport: {
            return getTaskResultAndDispatchStatusUpdate<DataDictionaryExport>(
              resultApi,
              token,
              taskType,
              dispatch,
              showAlertBanner,
              queryClient,
            )
          }
          default: {
            return assertNever(taskType)
          }
        }
      })
  })
}
