import { isProductionEnv } from '@netpurpose/utils'

export const sentryConfig = {
  enabled: process.env.NEXT_PUBLIC_MONITORING_ENABLED
    ? process.env.NEXT_PUBLIC_MONITORING_ENABLED === 'true'
    : false,
  dsn:
    process.env.NEXT_PUBLIC_SENTRY_DSN ||
    'https://ab8bad8e7426479db1f187c71b1a0551@o581227.ingest.sentry.io/5739465',
  environment: isProductionEnv() ? 'production' : 'preview',
  beforeBreadcrumb(breadcrumb) {
    if (breadcrumb.data?.url.includes('unleash')) {
      return {
        ...breadcrumb,
        data: {
          ...breadcrumb.data,
          url: breadcrumb.data.url.replace(/(userId=)[^&]+/, '$1[REDACTED]'),
        },
      }
    }

    return breadcrumb
  },
  beforeSend(event, hint) {
    // swallow recaptcha error - https://app.asana.com/0/1199950755555247/1200416793436331
    if (hint?.originalException === 'Timeout') {
      return null
    }
    return event
  },
  // Any errors about the "ResizeObserver loop" limit exceeded or completed with
  // undelivered notifications aren't actionable as they seem to be buried within
  // the Ant Design library and don't seem to cause any issues for us anyway.
  ignoreErrors: [/ResizeObserver loop/],
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1.0,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
}
