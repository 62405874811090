import firebase from 'firebase/compat/app'

export class MultifactorRequiredError extends Error {
  resolver: firebase.auth.MultiFactorResolver

  constructor(resolver: firebase.auth.MultiFactorResolver) {
    super('Second factor required')
    this.resolver = resolver
  }
}
