import { pickBy } from 'lodash'
import { FileSourceUpload, Source, SourceState, UrlSourceUpload } from '@netpurpose/types'
import { convertStringToEnum, valueIsDefined } from '@netpurpose/utils'
import { FileTypeEnum, ReportTypesEnum, StatusEnum } from '../../generated/facts'
import { formatDateForBackend } from '../datetime'
import { BackendSource } from './parseSource'

type BackendUrlSourceUpload = {
  name: string
  start: string | undefined
  end: string
  publishing_date: string | undefined
  report_type: string
  file_type: string
  entity_id: string | undefined
  original_url: string | undefined
  status: SourceState | undefined
}

type BackendFileSourceUpload = BackendUrlSourceUpload & {
  file: File
}

const formatCommonUpload = (
  payload: UrlSourceUpload | FileSourceUpload,
): BackendUrlSourceUpload => ({
  name: payload.fileName,
  start: payload.startDate ? formatDateForBackend(payload.startDate) : undefined,
  end: formatDateForBackend(payload.endDate),
  publishing_date: payload.publishingDate
    ? formatDateForBackend(payload.publishingDate)
    : undefined,
  report_type: payload.reportType,
  file_type: payload.sourceType,
  original_url: payload.sourceUrl,
  entity_id: payload.entityId?.toString(),
  status: payload.state,
})

export const formatUrlUpload = (payload: UrlSourceUpload): BackendUrlSourceUpload =>
  formatCommonUpload(payload)

export const formatFileUpload = (payload: FileSourceUpload): BackendFileSourceUpload => ({
  ...formatCommonUpload(payload),
  file: payload.sourceFile,
})

export const formatSource = (source: Partial<Source>): Partial<BackendSource> => ({
  ...pickBy(
    {
      source_id: source.id,
      name: source.name,
      start: source.start ? formatDateForBackend(source.start) : undefined,
      end: source.end ? formatDateForBackend(source.end) : undefined,
      publishing_date: source.publishingDate
        ? formatDateForBackend(source.publishingDate)
        : undefined,
      original_url: source.originalUrl,
      // NOTE: this is a temp shortcut, we should switch to use BE enums
      report_type: source.reportType
        ? convertStringToEnum(source.reportType as string, ReportTypesEnum)
        : undefined,
      checksum: source.checksum,
      entity_id: source.entityId || undefined,
      status: source.state ? convertStringToEnum(source.state as string, StatusEnum) : undefined,
    },
    valueIsDefined,
  ),
  url: source.url || '',
  file_type: convertStringToEnum(source.fileType as string, FileTypeEnum),
})
