import { FC, ReactNode } from 'react'
import { useAlertBanner, useModalContext } from '@netpurpose/core'
import {
  AlertBanner as NPUIAlertBanner,
  AlertBannerType as NPUIAlertBannerType,
} from '@netpurpose/np-ui'

export enum AlertBannerType {
  PendingUpload = 'pendingUpload',
  GeneralError = 'generalError',
  MutationError = 'mutationError',
  PasswordResetSuccess = 'passwordResetSuccess',
  RegisterSuccess = 'registerSuccess',
  ReportValueSuccess = 'reportValueSuccess',
  CustomError = 'customError',
}

type AlertBannerContent = Record<
  AlertBannerType,
  { level: NPUIAlertBannerType; content: ReactNode }
>

const alertBannerContent: AlertBannerContent = {
  [AlertBannerType.GeneralError]: {
    level: 'error',
    content: 'Something went wrong. Please try again later.',
  },
  [AlertBannerType.CustomError]: {
    level: 'error',
    content: null,
  },
  [AlertBannerType.MutationError]: {
    level: 'error',
    content: 'Action could not be completed:',
  },
  [AlertBannerType.PendingUpload]: {
    level: 'loading',
    content: "We're processing your upload.",
  },
  [AlertBannerType.PasswordResetSuccess]: {
    level: 'success',
    content: "Success! Your password has been changed and you're all set.",
  },
  [AlertBannerType.RegisterSuccess]: {
    level: 'success',
    content: 'Success! Your registration has been completed. Welcome to the Net Purpose community.',
  },
  [AlertBannerType.ReportValueSuccess]: {
    level: 'success',
    content: 'Success! Thanks for your feedback. We will get back to you soon.',
  },
}

type Props = {
  isVisible: boolean
  isModalOpen: boolean
  onClose: () => void
  type?: AlertBannerType
  text?: ReactNode
}

export const AlertBannerWithoutContext: FC<Props> = ({
  isVisible,
  type,
  isModalOpen,
  onClose,
  text,
}) => {
  if (!isVisible || !type) {
    return null
  }

  const { level, content } = alertBannerContent[type]

  return (
    <NPUIAlertBanner type={level} isOverlay={isModalOpen} onClose={onClose}>
      {content}
      {text && ` ${text}`}
    </NPUIAlertBanner>
  )
}

export const AlertBanner: FC = () => {
  const { isVisible, type, hideAlertBanner, text } = useAlertBanner<AlertBannerType>()
  const { isAnyModalOpen } = useModalContext()

  const handleClose = () => hideAlertBanner(type)

  return (
    // @ts-expect-error
    <AlertBannerWithoutContext
      {...{ isVisible, type, isModalOpen: isAnyModalOpen, onClose: handleClose, text }}
    />
  )
}
