import { AuthProviderError } from '#services/auth'

export class UnknownError extends Error {
  originalError?: AuthProviderError

  constructor(originalError?: AuthProviderError) {
    super('Unknown error')
    // @ts-expect-error
    this.originalError = originalError
  }
}
