import 'firebase/compat/analytics'
import firebase from 'firebase/compat/app'
import { useRouter } from 'next/router'
import React, { FC, ReactNode, useEffect, useState } from 'react'
import { useLoggedIn } from '@netpurpose/core'
import { logger } from '@netpurpose/utils'
import { config } from '#services/config'
import { AnalyticsEventName, AnalyticsEventParams } from '#types/analytics'

const analyticsEnabled =
  config.firebase.analyticsEnabled && config.firebase.measurementId && config.firebase.appId

// Privacy-first browsers such as Firefox will block analytics in such a way
// that we need to track whether tracking is supported at all and avoid throwing
// an error below if not, otherwise the whole application won't load.
let trackingSupported: null | boolean = null

type LogEvent = (
  eventName: firebase.analytics.EventName | AnalyticsEventName,
  eventParams?: AnalyticsEventParams,
  options?: Parameters<firebase.analytics.Analytics['logEvent']>['2'],
) => void

export type AnalyticsContextType =
  | (Omit<firebase.analytics.Analytics, 'logEvent'> & {
      logEvent: LogEvent
    })
  | null

export const defaultContext: AnalyticsContextType = null

export const AnalyticsContext = React.createContext<AnalyticsContextType>(defaultContext)

export const AnalyticsProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const router = useRouter()
  const { user } = useLoggedIn()
  const [tracking, setTracking] = useState<AnalyticsContextType>(defaultContext)

  const userId = user?.id

  useEffect(() => {
    // Disable analytics for local development.
    if (analyticsEnabled) {
      firebase.analytics.isSupported().then((isSupported) => {
        if (!isSupported) {
          logger.warn({ err: 'Analytics not supported' })
          trackingSupported = false
          return
        }

        trackingSupported = true
        setTracking(firebase.analytics())

        if (userId) {
          firebase.analytics().setUserId(userId)
        }
      })

      const handleRouteChange = (url: string) => {
        if (!tracking) {
          return
        }

        tracking.logEvent(AnalyticsEventName.PageView, {
          page_location: url,
          page_title: document?.title,
        })
      }

      router.events.on('routeChangeStart', handleRouteChange)

      return () => {
        router.events.off('routeChangeStart', handleRouteChange)
      }
    }
    return
  }, [router.events, tracking, userId])

  return <AnalyticsContext.Provider value={tracking}>{children}</AnalyticsContext.Provider>
}

export const useAnalytics = (): AnalyticsContextType => {
  const context = React.useContext(AnalyticsContext)
  if (analyticsEnabled && trackingSupported && !context === defaultContext) {
    throw new Error('useAnalytics must be used within a AnalyticsProvider')
  }
  return context
}
