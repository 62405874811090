// type safe version of Object.entries

import { AnyObject } from '#types'

type Entries<T> = {
  [K in keyof T]: [K, T[K]]
}[keyof T][]

export const entries = <T extends AnyObject>(obj: T): Entries<T> => {
  return Object.entries(obj) as Entries<T>
}

type Values<T> = T[keyof T][]

export const values = <T extends AnyObject>(obj: T): Values<T> => {
  return Object.values(obj) as Values<T>
}

export const assertNever = (q: never): never => {
  throw new Error(`Unexpected object: ${q}`)
}

// given a discriminated union T, the discriminating key K, and a value of that key V
// return the identified member of the union
export type DiscriminateUnion<T, K extends keyof T, V extends T[K]> =
  T extends Record<K, V> ? T : never

export type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>

export type SomeRequired<T, K extends keyof T> = Omit<T, K> & Required<Pick<T, K>>
