import { QueryClientProvider, useQueryClient } from '@tanstack/react-query'
import { FC, useEffect } from 'react'
import { UnprocessableError } from '@netpurpose/api'
import { useAlertBanner } from '@netpurpose/core'
import { AlertBannerType } from '#components/common/AlertBanner'

const GlobalErrorBanner: FC<{ children: React.ReactNode }> = ({ children }) => {
  const { showAlertBanner } = useAlertBanner()

  const queryClient = useQueryClient()

  useEffect(() => {
    queryClient.setDefaultOptions({
      mutations: {
        ...queryClient.getDefaultOptions().mutations,
        onError: (err: unknown) => {
          if (err instanceof UnprocessableError) {
            showAlertBanner(AlertBannerType.MutationError, err.message)
          } else {
            showAlertBanner(AlertBannerType.GeneralError)
          }
        },
      },
      queries: {
        ...queryClient.getDefaultOptions().queries,
        retry: (failureCount) => {
          const retry = 3
          const shouldRetry = failureCount < retry
          if (!shouldRetry) {
            showAlertBanner(AlertBannerType.GeneralError)
          }
          return shouldRetry
        },
      },
    })
  }, [queryClient, showAlertBanner])

  return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
}
export default GlobalErrorBanner
