import { useUnleashContext } from '@unleash/proxy-client-react'
import { Context, FC, useEffect } from 'react'
import { ThemeProvider } from 'styled-components'
import { Api, ApiContext, ApiProvider } from '@netpurpose/api'
import {
  AlertBannerContext,
  AlertBannerProvider,
  defaultAlertBannerContext,
  IAlertBannerContext,
  ModalProvider,
  useLoggedIn,
} from '@netpurpose/core'
import { GlobalStyles, lightTheme } from '@netpurpose/np-ui'
import { AlertBannerType } from '#components/common/AlertBanner'
import GlobalErrorBanner from '#components/common/GlobalErrorBanner'
import {
  AuthContext,
  AuthProvider,
  defaultContext as defaultAuthContext,
  IAuthContext,
} from '#context/auth'
import {
  defaultContext as defaultPortfolioSettingsContext,
  IPortfolioSettingsContext,
  PortfolioSettingsContext,
  PortfolioSettingsProvider,
} from '#context/portfolio'
import {
  defaultContext as defaultTaskContext,
  ITaskContext,
  TaskContext,
  TaskProvider,
} from '#context/tasks'
import { createApi } from '#services/api'
import { config } from '#services/config'
import {
  AnalyticsContext,
  AnalyticsContextType,
  AnalyticsProvider,
  defaultContext as defaultAnalyticsContext,
} from './analytics'
import { QueryClientProvider } from './queryClient'
import { UnleashProvider } from './UnleashProvider'
const TypedAlertBannerContext = AlertBannerContext as Context<IAlertBannerContext<AlertBannerType>>

type Props = {
  children: React.ReactNode
}

const UpdateUnleashContext: FC<{ children: React.ReactNode }> = ({ children }) => {
  const { user: loggedInUser } = useLoggedIn()
  const updateUnleashContext = useUnleashContext()

  // Despite groups being a list, control tool only allows users to be part of one group at a time
  const userGroup = loggedInUser?.groups[0]?.groupId

  useEffect(() => {
    // userId won't accept null, so default to undefined.
    updateUnleashContext({ ...(userGroup ? { userId: userGroup } : {}) })
    // Ideally we'd have updateUnleashContext in the dependency array, but we only
    // want to update the unleash context if the groupId has changed, and if unleash
    // is unable to fetch toggles for any reason then the context keeps changing,
    // causing an infinite loop and hammering the API.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userGroup])

  return <>{children}</>
}

const ContextProvider: FC<Props> = ({ children }) => (
  <UnleashProvider>
    <AuthProvider>
      <QueryClientProvider>
        <ApiProvider
          apiConfig={{
            axios: {
              getAxiosInstance: createApi,
              factsServiceBaseUrl: config.factServiceBaseUrl,
              modellingServiceBaseUrl: config.modellingServiceBaseUrl,
              usersServiceBaseUrl: config.userServiceBaseUrl,
            },
          }}
        >
          <AnalyticsProvider>
            <UpdateUnleashContext>
              <ThemeProvider theme={lightTheme}>
                <GlobalStyles />
                <AlertBannerProvider>
                  <GlobalErrorBanner>
                    <TaskProvider>
                      <PortfolioSettingsProvider>
                        <ModalProvider>{children}</ModalProvider>
                      </PortfolioSettingsProvider>
                    </TaskProvider>
                  </GlobalErrorBanner>
                </AlertBannerProvider>
              </ThemeProvider>
            </UpdateUnleashContext>
          </AnalyticsProvider>
        </ApiProvider>
      </QueryClientProvider>
    </AuthProvider>
  </UnleashProvider>
)

export default ContextProvider

type ContextConfig = {
  auth?: Partial<IAuthContext>
  tasks?: Partial<ITaskContext>
  portfolio?: Partial<IPortfolioSettingsContext>
  alertBanner?: Partial<IAlertBannerContext<AlertBannerType>>
  analytics?: AnalyticsContextType
}

type ConfigurableProps = {
  children: React.ReactNode
  config?: ContextConfig
}

// used for tests
export const ConfigurableContextProvider: FC<ConfigurableProps> = ({
  children,
  config: customConfig,
}) => {
  const {
    auth: authConfig = {},
    tasks: tasksConfig = {},
    portfolio: portfolioSettingsConfig = {},
    alertBanner: alertBannerConfig = {},
    analytics: analyticsConfig = defaultAnalyticsContext,
  } = customConfig || {}

  return (
    <AuthContext.Provider value={{ ...defaultAuthContext, ...authConfig }}>
      <AnalyticsContext.Provider value={analyticsConfig}>
        <TypedAlertBannerContext.Provider
          value={{
            ...(defaultAlertBannerContext as IAlertBannerContext<AlertBannerType>),
            ...alertBannerConfig,
          }}
        >
          <QueryClientProvider>
            <ApiContext.Provider
              value={{
                api: new Api({
                  axios: {
                    getAxiosInstance: createApi,
                    factsServiceBaseUrl: config.factServiceBaseUrl,
                    modellingServiceBaseUrl: config.modellingServiceBaseUrl,
                    usersServiceBaseUrl: config.userServiceBaseUrl,
                  },
                }),
              }}
            >
              <TaskContext.Provider value={{ ...defaultTaskContext, ...tasksConfig }}>
                <PortfolioSettingsContext.Provider
                  value={{ ...defaultPortfolioSettingsContext, ...portfolioSettingsConfig }}
                >
                  <ThemeProvider theme={lightTheme}>
                    <ModalProvider>{children}</ModalProvider>
                  </ThemeProvider>
                </PortfolioSettingsContext.Provider>
              </TaskContext.Provider>
            </ApiContext.Provider>
          </QueryClientProvider>
        </TypedAlertBannerContext.Provider>
      </AnalyticsContext.Provider>
    </AuthContext.Provider>
  )
}
