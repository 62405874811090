import { DisplayTheme, DisplayThemeWithSDG } from '@netpurpose/api'
import { SourceReportType, Theme } from '@netpurpose/types'
import { UnboundedRange } from '#components/dataByMetric/FilterDrawer/RangeFilterCollapse'
import { ChallengeProps } from '#components/feedback/feedbackTypes'
import { DocumentName } from '#components/library/Documentation/documents'

// i.e. event_name in BigQuery
export enum AnalyticsEventName {
  PageView = 'page_view',
  ButtonClick = 'button_click',
  LinkClick = 'link_click',
  FormSubmit = 'form_submit',
  TabSelect = 'tab_select',
  OptionSelect = 'option_select',
  Hover = 'hover',
  AutoFileDownload = 'auto_file_download',
  InputClick = 'input_click',
}

export enum AnalyticsButtonName {
  DownloadEntityReport = 'download_entity_report',
  ExportEntityReport = 'export_entity_report',
  ReTryExportEntityReport = 're_try_export_entity_report',
  DownloadPortfolio = 'download_portfolio',
  DownloadPortfolioPAIs = 'download_portfolio_PAIs',
  DownloadPortfolioSDGs = 'download_portfolio_SDGs',
  DownloadPortfolioSDGRevenue = 'download_portfolio_sdg_revenue',
  ExportPortfolio = 'export_portfolio',
  ExportPortfolioPAIs = 'export_portfolio_PAIs',
  ExportPortfolioSDGs = 'export_portfolio_SDGs',
  ExportPortfolioSDGRevenue = 'export_portfolio_sdg_revenue',
  ReTryExportPortfolio = 're_try_export_portfolio',
  OpenCompanyDataFilters = 'open_company_data_filters',
  ClearCompanyDataFilters = 'clear_company_data_filters',
  ClearCompanyDataFiltersDrawer = 'clear_company_data_filters_drawer',
  CancelCompanyDataFiltersDrawer = 'cancel_company_data_filters_drawer',
  OpenDataByMetricFilters = 'open_data_by_metric_filters',
  ClearDataByMetricFilters = 'clear_data_by_metric_filters',
  ClearDataByMetricFiltersDrawer = 'clear_data_by_metric_filters_drawer',
  CancelDataByMetricFiltersDrawer = 'cancel_data_by_metric_filters_drawer',
  CreatePortfolio = 'create_portfolio',
  CreateWatchlist = 'create_watchlist',
  MetricConfigConfirmDelete = 'metric_config_confirm_delete',
  MetricConfigCancelDelete = 'metric_config_cancel_delete',
  MetricConfigCancel = 'metric_config_cancel',
  MetricConfigSaveChanges = 'metric_config_save_changes',
  MetricConfigToggleIncludeEstimated = 'metric_config_toggle_include_estimated',
  MetricConfigToggleNotApplicable = 'metric_config_toggle_not_applicable',
  NavBarGetAPIKey = 'nav_bar_get_api_key',
  ViewEstimations = 'view_estimations',
  ExpandFactChangeHistoryAccordion = 'expand_fact_change_history_accordion',
  DownloadPortfolioTemplate = 'download_portfolio_template',
  EditPortfolio = 'edit_portfolio',
  PortfolioSDGsViewByRevenue = 'portfolio_sdgs_view_by_revenue',
  PortfolioSDGsViewByOutcomes = 'portfolio_sdgs_view_by_outcomes',
  PortfolioSDGsViewByAUM = 'portfolio_sdgs_view_by_aum',
  PortfolioSDGsViewByHoldings = 'portfolio_sdgs_view_by_holdings',
  PortfolioSDGsShowZeroPercent = 'portfolio_sdgs_show_zero_percent',
  PortfolioSDGsHideZeroPercent = 'portfolio_sdgs_hide_zero_percent',
  PortfolioSDGsTableMenuIcon = 'portfolio_sdgs_table_menu_icon',
  ExportDataDictionary = 'export_data_dictionary',
  DownloadDataDictionary = 'download_data_dictionary',
  PortfolioDashboardToggleNotDisclosed = 'portfolio_dashboard_toggle_not_disclosed',
  PortfolioDashboardToggleNotApplicable = 'portfolio_dashboard_toggle_not_applicable',
  CompanyDashboardToggleNotDisclosed = 'company_dashboard_toggle_not_disclosed',
  CompanyDashboardToggleNotApplicable = 'company_dashboard_toggle_not_applicable',
  ExportHistoryDownload = 'export_history_download',
  ExportHistoryRetry = 'export_history_retry',
  ExportHistoryDropdownDownload = 'export_history_dropdown_download',
  ExportHistoryDropdownRetry = 'export_history_dropdown_retry',
  ExportEntitySDGRevenueAlignmentExcel = 'export_entity_sdg_revenue_alignment_excel',
  GetAccessResearch = 'get_access_research',
  GetAccessEstimationDetail = 'get_access_estimation_detail',
  SDGSeeResearch = 'sdg_see_research',
}

export enum AnalyticsLinkName {
  ViewEstimationsWriteUp = 'view_estimations_write_up',
  ToMetricConfigListFromNavBarMenu = 'to_metric_config_list_from_nav_bar_menu',
  ToMetricConfigListFromCompany = 'to_metric_config_list_from_company',
  ToMetricConfigListFromPortfolio = 'to_metric_config_list_from_portfolio',
  ToMetricConfigListFromPortfolioComparison = 'to_metric_config_list_from_portfolio_comparison',
  ToMetricConfigListFromPortfolioDisclosure = 'to_metric_config_list_from_portfolio_disclosure',
  ToMetricConfigFromMetricConfigList = 'to_metric_config_from_metric_config_list',
  ToMetricConfig = 'to_metric_config',
  ToNewMetricConfigFromMetricConfigList = 'to_new_metric_config_from_metric_config_list',
  NavBarViewLibrary = 'nav_bar_view_library',
  NavBarViewWhatsNew = 'nav_bar_view_whats_new',
  ViewFactFromDataChangeLog = 'view_fact_from_data_change_log',
  OpenFactViewerForYoYVarianceReason = 'open_fact_viewer_for_yoy_variance_reason',
  OpenFactViewerFromKpiResultHover = 'open_fact_viewer_from_kpi_result_hover',
  OpenFactViewerFromReportedDataHover = 'open_fact_viewer_from_reported_data_hover',
  ViewEstimationsFromEstimationDerivationHover = 'view_estimations_from_estimation_derivation_hover',
  ToActivitiesPageFromDomainDetail = 'to_activities_page_from_domain_detail',
  ToEntityDetailFromDomainDetail = 'to_entity_detail_from_domain_detail',
  ViewPortfolioSDGsOutcomesMethodology = 'view_portfolio_sdgs_methodology',
  ViewPortfolioSDGsRevenueMethodology = 'view_portfolio_sdgs_revenue_methodology',
  ViewCompanySDGsRevenueMethodology = 'view_company_sdgs_revenue_methodology',
  OpenFileFromLibraryDocumentation = 'open_file_from_library_documentation',
  PortfolioHoldingsWithSDGFilterApplied = 'portfolio_holdings_with_sdg_filter_applied',
  PortfolioDashboardWithSDGFilterApplied = 'portfolio_dashboard_with_sdg_filter_applied',
}

export enum AnalyticsFormName {
  ReportValue = 'report_value',
  CompanyDataFilters = 'company_data_filters',
  DataByMetricFilters = 'data_by_metric_filters',
  AddCompaniesToWatchlist = 'add_companies_to_watchlist',
  CustomiseMetricConfigFilters = 'customise_metric_config_filters',
  EditPortfolio = 'edit_portfolio',
}

export enum AnalyticsOptionSelectName {
  DataByMetricSelectTheme = 'data_by_metric_select_theme',
  DataByMetricSelectMetric = 'data_by_metric_select_metric',
  PortfolioDashboardSelectTheme = 'portfolio_dashboard_select_theme',
  PortfolioDashboardSelectSDG = 'portfolio_dashboard_select_sdg',
  PortfolioDashboardSelectScope = 'portfolio_dashboard_select_scope',
  MetricConfigSelectMetric = 'metric_config_select_metric',
}

export enum AnalyticsDownloadFileName {
  Portfolio = 'portfolio',
  DataDictionary = 'data_dictionary',
  Unknown = 'unknown',
}

export enum AnalyticsInputName {
  ExportHistorySearchBar = 'export_history_search_bar',
}

// i.e. event_params in BigQuery
export type AnalyticsEventParams = {
  page_location?: string
  page_title?: string
  button_name?: AnalyticsButtonName
  link_name?: AnalyticsLinkName
  form_name?: AnalyticsFormName
  file_name?: AnalyticsDownloadFileName | DocumentName
  tab_name?: string
  option_select_name?: AnalyticsOptionSelectName
  option_value?: string | number
  entity_id?: number
  fact_id?: number
  // Google Analytics automatically converts this to a comma-separated string.
  portfolio_ids?: number[]
  message?: string
  report_value_id?: ChallengeProps['id']
  report_value_type?: ChallengeProps['type']
  report_value_message?: string
  active_filters?: Record<string, (string | number)[] | UnboundedRange>
  theme?: Theme | DisplayTheme | DisplayThemeWithSDG | null
  question_id?: number | null
  question_name?: string
  portfolio_id?: number | null
  portfolio_name?: string
  metric_config_id?: string | null
  is_new_metric_config?: boolean
  is_editing_metric_config_name?: boolean
  hover_item_name?: string
  table_name?: string
  location?: string
  kpi_result_id?: string
  technology_id?: string
  input_name?: AnalyticsInputName
  taxon_id?: number
  datapoint_id?: string
  source_id?: number
  source_name?: string
  source_report_type?: SourceReportType | undefined
  source_url?: string
}
