import { InvalidEmailError } from './InvalidEmailError'
import { InvalidPasswordResetCodeError } from './InvalidPasswordResetCodeError'
import { MultifactorRequiredError } from './MultifactorRequiredError'
import { NetworkRequestError } from './NetworkRequestError'
import { NoAuthUserError } from './NoAuthUserError'
import { RecaptchaDupeError } from './RecaptchaDupeError'
import { RequiresRecentLoginError } from './RequiresRecentLoginError'
import { UnknownError } from './UnknownError'
import { UserNotFoundError } from './UserNotFoundError'
import { WeakPasswordError } from './WeakPasswordError'

export type AuthError =
  | UserNotFoundError
  | InvalidEmailError
  | UnknownError
  | MultifactorRequiredError
  | WeakPasswordError
  | NoAuthUserError
  | InvalidPasswordResetCodeError
  | RequiresRecentLoginError
  | RecaptchaDupeError
  | NetworkRequestError

export {
  UserNotFoundError,
  InvalidEmailError,
  UnknownError,
  MultifactorRequiredError,
  WeakPasswordError,
  NoAuthUserError,
  InvalidPasswordResetCodeError,
  RequiresRecentLoginError,
  RecaptchaDupeError,
  NetworkRequestError,
}
