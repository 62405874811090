import { IAlertBannerContext } from '@netpurpose/core'
import { ResultStatus } from '@netpurpose/types'
import { AlertBannerType } from '#components/common/AlertBanner'
import { State, TaskType, TaskTypeAndStatus } from '#context/tasks'
import { entries, values } from '#services/typeHelpers'

const toArray = (state: State) =>
  entries(state).reduce(
    (acc, [type, tasksById]) =>
      acc.concat(values(tasksById).map((task) => ({ type, status: task.status }))),
    [] as TaskTypeAndStatus[],
  )

const onlyUpload = (tasks: TaskTypeAndStatus[]) =>
  tasks.filter((task) => task.type === TaskType.PortfolioCreation)

const anyTasksHaveStatus = (status: ResultStatus, tasks: TaskTypeAndStatus[]) =>
  tasks.some((task) => task.status === status)

export const updateBanners = (
  state: State,
  showAlertBanner: IAlertBannerContext<AlertBannerType>['showAlertBanner'],
  hideAlertBanner: IAlertBannerContext<AlertBannerType>['hideAlertBanner'],
) => {
  const tasks = toArray(state)

  if (anyTasksHaveStatus('pending', onlyUpload(tasks))) {
    return showAlertBanner(AlertBannerType.PendingUpload)
  }

  hideAlertBanner(AlertBannerType.PendingUpload)
}
