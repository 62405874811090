import { stringify } from 'qs'
import { SourceTask, SourceTaskStep, SourceTaskType } from '@netpurpose/types'
import { AxiosInstances } from '../../client'
import { joinPaths } from '../../utils'
import { SimpleAbstractModelApi } from '../SimpleAbstractModelApi'
import { formatSourceTask } from './formatSourceTask'
import { parseSourceTask } from './parseSourceTask'
import { BackendSourceTask } from './types'

export class SourceTaskApi extends SimpleAbstractModelApi<BackendSourceTask, SourceTask> {
  endpoint = '/tasks/source'
  modelFormatter = formatSourceTask
  reverseFieldMap = undefined

  constructor(axiosInstances: AxiosInstances) {
    super(axiosInstances, parseSourceTask)
    this.abandonTask = this.abandonTask.bind(this)
    this.getNextTask = this.getNextTask.bind(this)
    this.submitTask = this.submitTask.bind(this)
    this.addTaskElapsedTime = this.addTaskElapsedTime.bind(this)
    this.createTasks = this.createTasks.bind(this)
  }

  async abandonTask(taskId: SourceTask['id']) {
    await this.api.put(joinPaths(this.endpoint, taskId, 'abandon'))
  }

  async getNextTask(step: SourceTaskStep | null): Promise<SourceTask | void> {
    if (!step) {
      return
    }
    const { data } = await this.api.get(joinPaths(this.endpoint, 'next', step))
    if (!data) {
      return
    }
    return parseSourceTask(data)
  }

  async submitTask({ taskId }: { taskId: SourceTask['id'] }): Promise<void> {
    await this.api.put(
      joinPaths(this.endpoint, taskId, `submit?${stringify({ reports_not_out: false })}`),
    )
  }

  async addTaskElapsedTime({
    taskId,
    elapsedTime,
  }: {
    taskId: SourceTask['id']
    elapsedTime: number
  }) {
    await this.api.put(joinPaths(this.endpoint, taskId, 'update_elapsed', elapsedTime))
  }

  async createTasks(values: {
    file: File
    phase: number
    priority: number
    taskType: SourceTaskType
  }) {
    const formData = new FormData()
    formData.append('source_tasks_csv', values.file)
    formData.append('phase', values.phase.toString())
    formData.append('priority', values.priority.toString())
    formData.append('task_type', values.taskType)

    const { data } = await this.api.post(joinPaths(this.endpoint, 'create_tasks'), formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    return data?.result_id as string
  }
}
