import React, { FC, useCallback, useState } from 'react'
import { Portfolio } from '@netpurpose/api'

type PortfolioSettingsState = {
  selectedPortfolioIds: Portfolio['id'][]
}

export type IPortfolioSettingsContext = PortfolioSettingsState & {
  setSelectedPortfolioIds: (selectedPortfolioIds: Portfolio['id'][]) => void
}

const defaultState: PortfolioSettingsState = {
  selectedPortfolioIds: [],
}
export const defaultContext: IPortfolioSettingsContext = {
  ...defaultState,
  setSelectedPortfolioIds: () => {},
}

export const PortfolioSettingsContext = React.createContext(defaultContext)

export const PortfolioSettingsProvider: FC<{ children: React.ReactNode }> = (props) => {
  const [state, setState] = useState(defaultState)

  const setSelectedPortfolioIds = useCallback(
    (selectedPortfolioIds: Portfolio['id'][]) =>
      setState((prevState) => ({ ...prevState, selectedPortfolioIds })),
    [],
  )

  return (
    <PortfolioSettingsContext.Provider value={{ ...state, setSelectedPortfolioIds }} {...props} />
  )
}

export const usePortfolioSettings = (): IPortfolioSettingsContext => {
  const context = React.useContext(PortfolioSettingsContext)
  if (context === defaultContext) {
    throw new Error('usePortfolio must be used within a PortfolioProvider')
  }
  return context
}
