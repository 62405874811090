import { useQueryClient } from '@tanstack/react-query'
import React, { FC, useEffect, useReducer } from 'react'
import { useApi } from '@netpurpose/api'
import { useAlertBanner, useInterval } from '@netpurpose/core'
import { ITaskContext, reducer, State, TaskType } from '#context/tasks'
import { config } from '#services/config'
import { checkPendingTaskResults } from './checkPendingTaskResults'
import { updateBanners } from './updateBanners'

const defaultState: State = {
  portfolioCreation: {},
  portfolioExport: {},
  portfolioPAIsExport: {},
  portfolioSDGsExport: {},
  portfolioSDGRevenueExport: {},
  entityExport: {},
  dataDictionaryExport: {},
}

export const defaultContext: ITaskContext = {
  createPortfolio: () => {},
  requestPortfolioExport: () => {},
  requestPortfolioPAIsExport: () => {},
  requestPortfolioSDGsExport: () => {},
  requestPortfolioSDGRevenueExport: () => {},
  requestEntityExport: () => {},
  requestDataDictionaryExport: () => {},
  removeExport: () => {},
  portfolioCreationTasks: [],
  portfolioExportRequests: [],
  portfolioPAIsExportRequests: [],
  portfolioSDGsExportRequests: [],
  portfolioSDGRevenueExportRequests: [],
  entityExportRequests: [],
  dataDictionaryExportRequests: [],
}
export const TaskContext = React.createContext(defaultContext)

export const TaskProvider: FC<{ children: React.ReactNode }> = (props) => {
  const queryClient = useQueryClient()
  const [state, dispatch] = useReducer(reducer, defaultState)
  const { api } = useApi()

  const { showAlertBanner, hideAlertBanner } = useAlertBanner()

  useInterval(() => {
    checkPendingTaskResults(api.result, state, dispatch, queryClient, showAlertBanner)
  }, config.taskResultsPollDelay)

  useEffect(
    () => updateBanners(state, showAlertBanner, hideAlertBanner),
    [hideAlertBanner, showAlertBanner, state],
  )

  const portfolioCreationTasks = Object.values(state.portfolioCreation)
  const createPortfolio: ITaskContext['createPortfolio'] = (token, portfolio) =>
    dispatch({ actionType: 'new', taskType: TaskType.PortfolioCreation, token, initial: portfolio })

  const portfolioExportRequests = Object.values(state.portfolioExport)
  const requestPortfolioExport: ITaskContext['requestPortfolioExport'] = (
    token,
    portfolioId,
    portfolioName,
    metricConfigId,
  ) => {
    dispatch({
      actionType: 'new',
      taskType: TaskType.PortfolioExport,
      token,
      initial: portfolioId,
      portfolioName,
      metricConfigId,
    })
  }

  const portfolioPAIsExportRequests = Object.values(state.portfolioPAIsExport)

  const requestPortfolioPAIsExport: ITaskContext['requestPortfolioPAIsExport'] = (
    token,
    portfolioId,
    portfolioName,
  ) => {
    dispatch({
      actionType: 'new',
      taskType: TaskType.PortfolioPAIsExport,
      token,
      initial: portfolioId,
      portfolioName,
    })
  }

  const portfolioSDGsExportRequests = Object.values(state.portfolioSDGsExport)

  const requestPortfolioSDGsExport: ITaskContext['requestPortfolioSDGsExport'] = (
    token,
    portfolioId,
    portfolioName,
  ) => {
    dispatch({
      actionType: 'new',
      taskType: TaskType.PortfolioSDGsExport,
      token,
      initial: portfolioId,
      portfolioName,
    })
  }

  const portfolioSDGRevenueExportRequests = Object.values(state.portfolioSDGRevenueExport)

  const requestPortfolioSDGRevenueExport: ITaskContext['requestPortfolioSDGRevenueExport'] = (
    token,
    portfolioId,
    portfolioName,
  ) => {
    dispatch({
      actionType: 'new',
      taskType: TaskType.PortfolioSDGRevenueExport,
      token,
      initial: portfolioId,
      portfolioName,
    })
  }

  const entityExportRequests = Object.values(state.entityExport)
  const requestEntityExport: ITaskContext['requestEntityExport'] = (token, entityId, entityName) =>
    dispatch({
      actionType: 'new',
      taskType: TaskType.EntityExport,
      token,
      initial: entityId,
      entityName,
    })

  const dataDictionaryExportRequests = Object.values(state.dataDictionaryExport)
  const requestDataDictionaryExport: ITaskContext['requestDataDictionaryExport'] = (token) =>
    dispatch({
      actionType: 'new',
      taskType: TaskType.DataDictionaryExport,
      token,
      initial: 'data-dictionary-export',
    })

  const removeExport = (taskType: TaskType, result: string | undefined) => {
    dispatch({ actionType: 'remove', taskType, result })
  }

  return (
    <TaskContext.Provider
      value={{
        createPortfolio,
        portfolioCreationTasks,
        requestPortfolioExport,
        requestPortfolioPAIsExport,
        requestPortfolioSDGsExport,
        requestPortfolioSDGRevenueExport,
        portfolioExportRequests,
        portfolioPAIsExportRequests,
        portfolioSDGsExportRequests,
        portfolioSDGRevenueExportRequests,
        requestEntityExport,
        entityExportRequests,
        requestDataDictionaryExport,
        dataDictionaryExportRequests,
        removeExport,
      }}
      {...props}
    />
  )
}

export const useTasks = (): ITaskContext => {
  const context = React.useContext(TaskContext)
  if (context === defaultContext) {
    throw new Error('useTasks must be used within a TaskProvider')
  }
  return context
}
