import { AxiosResponse } from 'axios'

export class RecaptchaFailedError extends Error {
  originalError?: AxiosResponse

  constructor(originalError?: AxiosResponse) {
    super('Recaptcha failed')
    // @ts-expect-error
    this.originalError = originalError
  }
}
