import Head from 'next/head'
// import { hijackEffects } from 'stop-runaway-react-effects'
import { CornerRibbon } from '@netpurpose/np-ui'
import { isPreviewEnv } from '@netpurpose/utils'
import ContextProvider from '#context/ContextProvider'
import { initialiseAuthProvider } from '#services/auth'
import type { AppProps } from 'next/app'

// Uncomment to monitor infinite loops in useEffect hooks.
// if (process.env.NODE_ENV === 'development') {
//   hijackEffects()
// }

initialiseAuthProvider()

const App = ({ Component, pageProps }: AppProps): JSX.Element => (
  <>
    <Head>
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    </Head>
    {isPreviewEnv() && <CornerRibbon>Preview</CornerRibbon>}
    <ContextProvider>
      <Component {...pageProps} />
    </ContextProvider>
  </>
)

export default App
