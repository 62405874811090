import {
  QueryClientProvider as DefaultQueryClientProvider,
  QueryClient,
} from '@tanstack/react-query'
import { FC } from 'react'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

export const QueryClientProvider: FC<{ children: React.ReactNode }> = ({ children, ...props }) => (
  <DefaultQueryClientProvider client={queryClient} {...props}>
    {children}
  </DefaultQueryClientProvider>
)
